import type { OffsetType } from '@/helpers/interfaces'
import type { ActionContext } from 'vuex'
import type { StateInterface } from '@/store/index'
import type { ProjectStatus } from '@api/index'
import { fetchAppProjects, fetchApprovedProjects } from '@api/index'
import { Dialogs } from '@/store/dialog'
import { PARTNER_PROJECTS, type ProjectId } from '@/helpers/constants'
import type {
  PersonalSubscriptionType,
  TechnicalAndBusinessSubscriptionType,
} from '@/helpers/pricing'
import i18n from '@/i18n'

export interface Project {
  projectId: ProjectId
  name: string
  description: string
  type: OffsetType
  price: number
  default: boolean
  link?: string
  imageUrl?: string
  projectPartnerLogo?: string
  countries?: string[]
  channels?: {
    app?: (PersonalSubscriptionType | TechnicalAndBusinessSubscriptionType)[]
    api?: (PersonalSubscriptionType | TechnicalAndBusinessSubscriptionType)[]
  }
  availability: number | null
  status: ProjectStatus
  statusInitiatedAt: string
}
export interface ProjectsState {
  projectList: Project[]
  projectListForFree: Project[]
  projectListForPaid: Project[]
  projectListForDollar: Project[]
  approvedProjects: Project[]
  deprecatedProjects: Project[]
}

export const projectsModule = {
  state: () =>
    ({
      projectList: [],
      projectListForFree: [],
      projectListForPaid: [],
      projectListForDollar: [],
      approvedProjects: [],
      deprecatedProjects: [],
    } as ProjectsState),
  mutations: {
    setProjectList(state: ProjectsState, payload: Project[]) {
      state.projectList = payload
    },
    setProjectListForFree(state: ProjectsState, payload: Project[]) {
      state.projectListForFree = payload
    },
    setProjectListForPaid(state: ProjectsState, payload: Project[]) {
      state.projectListForPaid = payload
    },
    setProjectListForDollar(state: ProjectsState, payload: Project[]) {
      state.projectListForDollar = payload
    },
    setApprovedProjects(state: ProjectsState, payload: Project[]) {
      state.approvedProjects = payload
    },
    setDeprecatedProjects(state: ProjectsState, payload: Project[]) {
      state.deprecatedProjects = payload
    },
  },
  actions: {
    async setProjectList({ getters, commit }: ActionContext<ProjectsState, StateInterface>) {
      try {
        const [
          { data: projectList },
          { data: projectListForFree },
          { data: projectListForPaid },
          { data: projectListForDollar },
          { data: approvedProjects },
          { data: deprecatedProjects },
        ] = await Promise.all([
          fetchAppProjects({ currency: getters.getUserCurrencyCode }),
          fetchAppProjects({ plan: 'freeBusiness', currency: getters.getUserCurrencyCode }),
          fetchAppProjects({ plan: 'starterBusiness', currency: getters.getUserCurrencyCode }),
          fetchAppProjects({ plan: 'starterBusiness', currency: 'USD' }),
          fetchApprovedProjects(),
          fetchAppProjects({ statuses: 'deprecated' }),
        ])
        commit('setProjectList', projectList)
        commit('setProjectListForFree', projectListForFree)
        commit('setProjectListForPaid', projectListForPaid)
        commit('setProjectListForDollar', projectListForDollar)
        commit('setApprovedProjects', approvedProjects)
        commit('setDeprecatedProjects', deprecatedProjects)
      } catch (e) {
        console.error(e)
      }
    },
    async openPriceComparisonPopup({
      commit,
      getters,
      dispatch,
    }: ActionContext<ProjectsState, StateInterface>) {
      if (getters.getChecklistOpenState) {
        commit('setOpenState', false)
      }
      await dispatch('openDialog', {
        name: Dialogs.CUSTOM.PRICE_COMPARISON,
        options: {
          width: 776,
        },
      })
    },
  },
  getters: {
    getApprovedProjects(state: ProjectsState): Project[] {
      return state.approvedProjects
    },
    getDeprecatedProjects(state: ProjectsState): Project[] {
      return state.deprecatedProjects
    },
    getAllProjects(state: ProjectsState): Project[] {
      return [...state.approvedProjects, ...state.deprecatedProjects]
    },
    getProjectsWithLink(state: ProjectsState): Project[] {
      return state.approvedProjects.filter((project) => project.link)
    },
    getPartnerProjects(state: ProjectsState): Project[] {
      return state.projectList.filter((project) => PARTNER_PROJECTS.includes(project.projectId))
    },
    getDefaultProjects: (state: ProjectsState): Project[] => {
      return state.projectList.filter((project) => project.default)
    },
    getAppProjects: (state: ProjectsState): Project[] => {
      return state.projectList
    },
    getPaidProjectPriceById:
      (state: ProjectsState) =>
      (projectId: string): number => {
        return (
          state.projectListForPaid.find((project) => project.projectId === projectId)?.price || 0
        )
      },
    getDefaultPaidProjectPriceByType:
      (state: ProjectsState) =>
      (type: OffsetType): number => {
        return (
          state.projectListForPaid.find((project) => project.type === type && project.default)
            ?.price || 0
        )
      },
    getDefaultProjectPriceByType:
      (state: ProjectsState) =>
      (type: OffsetType, freeBusinessPrice = false): number => {
        const projects = freeBusinessPrice ? state.projectListForFree : state.projectList
        return projects.find((project) => project.type === type && project.default)?.price || 0
      },
    getProjectDollarPriceByType:
      (state: ProjectsState) =>
      (type: OffsetType): number => {
        return (
          state.projectListForDollar.find((project) => project.type === type && project.default)
            ?.price || 0
        )
      },
    getFormattedDefaultProjectPriceByType:
      (state: ProjectsState, getters) =>
      (type: OffsetType, freeBusinessPrice = false): string => {
        const selectedProjectPrice = getters.getDefaultProjectPriceByType(type, freeBusinessPrice)
        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: getters.getUserCurrencyCode,
          minimumFractionDigits: 2,
          maximumFractionDigits: 3,
        })
        return formatter.format(selectedProjectPrice)
      },
    getProjectById:
      (state: ProjectsState) =>
      (projectId: string): Project | undefined => {
        return state.projectList.find((project) => project.projectId === projectId)
      },
    getProjectPriceById:
      (state: ProjectsState, getters) =>
      (projectId: string, freeBusinessPrice = false): number => {
        return getters.getProjectById(projectId, freeBusinessPrice)?.price || 0
      },
    getFormattedProjectPriceById:
      (state: ProjectsState, getters) =>
      (projectId: string): string => {
        const selectedProjectPrice =
          state.projectList.find((project) => project.projectId === projectId)?.price || 0
        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: getters.getUserCurrencyCode,
          minimumFractionDigits: 2,
          maximumFractionDigits: 3,
        })
        return formatter.format(selectedProjectPrice)
      },
    getVerboseActionPricingByType:
      (state: ProjectsState, getters) =>
      (type: OffsetType, freeBusinessPrice = false, separator = '/'): string => {
        const formattedPrice = getters.getFormattedDefaultProjectPriceByType(
          type,
          freeBusinessPrice,
        )
        const descriptor = i18n.global.t(`${type}.action_descriptor`)
        return `${formattedPrice} ${separator} ${descriptor}`
      },
    getProjectOffsetTypeById:
      (state: ProjectsState) =>
      (projectId: string): OffsetType | undefined => {
        return state.approvedProjects.find((project) => project.projectId === projectId)?.type
      },
  },
}
