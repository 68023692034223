<template>
  <section class="public-map-container">
    <h2 class="title">{{ accountImpacts?.length ? t('title') : t('title_alternative') }}</h2>
    <div class="map-container">
      <div class="carousel-container">
        <v-icon
          v-if="projectsToDisplay.length > 1"
          class="chevron carousel-chevron left"
          @click.prevent="onCarouselChevronClick('left')"
        >
          mdi-chevron-left
        </v-icon>
        <v-carousel
          v-if="projectsToDisplay.length"
          id="public-map-carousel"
          ref="carousel"
          v-model="currentSlide"
          hide-delimiters
          :show-arrows="false"
          :height="carouselHeight"
        >
          <v-carousel-item v-for="project in projectsToDisplay" :key="project.projectId">
            <div class="carousel-item-wrapper">
              <ProjectDetailsCard class="carousel-item" :project="project" />
            </div>
          </v-carousel-item>
        </v-carousel>
        <v-icon
          v-if="projectsToDisplay.length > 1"
          class="chevron carousel-chevron right"
          @click.prevent="onCarouselChevronClick('right')"
        >
          mdi-chevron-right
        </v-icon>
      </div>
      <div class="markers">
        <div
          v-for="(_, index) in projectsToDisplay"
          :key="index"
          :class="['marker-dot', { visible: currentSlide === index }]"
        />
      </div>
      <PublicMapPin
        class="map-pin"
        v-for="(pin, index) in mapPins"
        :key="index"
        :pin="pin"
        :is-active="currentSlide === pin.index"
        @click="() => handlePinClick(pin.index)"
      />
      <PublicMapIcon class="global-map" id="public-map" />
    </div>
    <div class="about-our-projects">
      <a class="projects-link" href="https://www.getgreenspark.com/projects" target="_blank">
        {{ t('about_our_projects') }}
      </a>
    </div>
  </section>
</template>

<script lang="ts">
import PublicMapIcon from '@/components/icons/ImpactIcons/PublicMapIcon.vue'
import type { Project } from '@/store/projects'
import { Utils } from '@/helpers/mixins/utilsMixin'
import type { MapPin } from '@/components/your-impact/PublicMapPin.vue'
import PublicMapPin from '@/components/your-impact/PublicMapPin.vue'
import ProjectDetailsCard from '@/components/your-impact/ProjectDetailsCard.vue'
import { defineComponent } from 'vue'
import type { TotalImpact } from '@/helpers/interfaces'
import type { Account } from '@/store'

export default defineComponent({
  name: 'PublicMap',
  components: {
    ProjectDetailsCard,
    PublicMapIcon,
    PublicMapPin,
  },
  mixins: [Utils],
  data() {
    return {
      currentSlide: 0,
      carouselHeight: 200,
    }
  },
  computed: {
    projects(): Project[] {
      return this.$store.getters['getAllProjects']
    },
    accountImpacts(): TotalImpact[] {
      return this.$store.getters['getAccountImpacts'].filter((impact) => impact.amount > 0)
    },
    projectsWithLink(): Project[] {
      return this.$store.getters['getProjectsWithLink']
    },
    account(): Account {
      return this.$store.getters['getAccount']
    },
    projectsToDisplay(): Project[] {
      const accountSignupDate = new Date(this.account.signupDate).getTime()
      const projectWithAmount = this.projects
        .filter(({ link }) => link)
        .filter(({ projectId, type, status, statusInitiatedAt }) =>
          // display all project pins and cards if there is no impact data
          // otherwise only display projects with impacts
          this.accountImpacts?.length
            ? (type === 'carbon' &&
                this.accountImpacts.some((i) => i.amount && i.type === 'carbon') &&
                (status === 'deprecated'
                  ? accountSignupDate < new Date(statusInitiatedAt).getTime()
                  : true)) ||
              (this.getImpactsByProject(projectId)?.projectId === projectId &&
                this.getImpactsByProject(projectId)?.amount > 0)
            : status === 'approved',
        )
      return projectWithAmount.length ? projectWithAmount : this.projectsWithLink
    },
    mapPins(): MapPin[] {
      return this.projectsToDisplay.flatMap((project, index) => {
        return project.countries
          ? project.countries
              .filter((country) => country !== '')
              .map((country) => ({
                index,
                country,
                type: project.type,
                projectId: project.projectId,
                availability: project.availability,
                link: project.link,
              }))
          : []
      })
    },
  },
  created() {
    this.handleResize()
    window.addEventListener('resize', this.handleResize)
  },
  methods: {
    t(key: string) {
      return this.$t(`PublicMap.${key}`)
    },
    getImpactsByProject(projectId: string) {
      return this.$store.getters['getImpactsByProject'](projectId)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleResize)
    },
    setPin(pin: MapPin) {
      const mapCell = document.getElementById(
        `public-map-${pin.country
          .toLowerCase()
          .replace(/\s*-\s*/g, '-')
          .replaceAll(' ', '-')}-${pin.type}`,
      ) as HTMLElement
      const SVGRect = mapCell?.getBoundingClientRect() || new DOMRect()
      const pinIcon = document.getElementById(
        `public-map-pin-${pin.country.replace(/\s*-\s*/g, '-')}-${pin.type}`,
      ) as HTMLElement
      const pinIconHeight = pinIcon?.getBoundingClientRect().height || 0
      const pinIconWidth = pinIcon?.getBoundingClientRect().width || 0
      const cellHeight = SVGRect.height
      const cellWidth = SVGRect.width
      // we need to align the cell position as the app has 30px padding on the left on md-and-up (960px) screens
      const alignmentX = window.innerWidth <= 960 ? 0 : 30
      const mapCellPosition = {
        top: SVGRect.top + window.scrollY,
        left: SVGRect.left + window.scrollX - alignmentX,
      }

      if (mapCell && pinIcon) {
        pinIcon.style.top = `${mapCellPosition.top - pinIconHeight + cellHeight}px`
        pinIcon.style.left = `${mapCellPosition.left - pinIconWidth / 2 + cellWidth / 2}px`
      }
    },
    initMap() {
      this.mapPins.forEach((pin: MapPin) => {
        this.setPin(pin)
      })
    },
    resizeCarouselHeight() {
      if (window.innerWidth <= 1280) {
        this.carouselHeight = 425
      } else {
        this.carouselHeight = 186
      }
    },
    handlePinClick(index: number) {
      this.currentSlide = index
    },
    handleResize() {
      this.resizeCarouselHeight()
      this.debounce(this.initMap, 100)()
    },
    onCarouselChevronClick(direction: 'left' | 'right') {
      const max = this.projectsToDisplay.length - 1
      switch (direction) {
        case 'left':
          if (this.currentSlide > 0) this.currentSlide--
          else this.currentSlide = max
          break
        case 'right':
          if (this.currentSlide < max) this.currentSlide++
          else this.currentSlide = 0
          break
      }
    },
  },
})
</script>

<style lang="scss" scoped>
@import '~vuetify/settings';

.public-map-container {
  width: 100%;
  background: var(--ui-white);
  border-radius: 8px;
  padding: 8px;

  .title {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 16px;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      font-size: 24px;
      line-height: 29px;
    }
  }

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    padding: 32px 24px;
  }
}

.map-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 8px;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    gap: 32px;
    margin-bottom: 20px;
  }

  .map-pin,
  .global-map {
    display: none;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      display: initial;
    }
  }
}

.carousel-container {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    gap: 8px;
  }

  > .carousel-chevron {
    color: #212121;
    z-index: 1;
    font-size: 12px;
    width: 12px;
    height: 12px;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      font-size: 32px;
      width: 32px;
      height: 32px;
    }

    &.left {
      margin-left: -8px;

      @media #{map-get($display-breakpoints, 'sm-and-up')} {
        margin-left: -8px;
      }
    }

    &.right {
      margin-right: -8px;

      @media #{map-get($display-breakpoints, 'sm-and-up')} {
        margin-right: -8px;
      }
    }

    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }

    &::after {
      opacity: 0 !important;
    }
  }
}

.carousel-item-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .carousel-item {
    border-radius: 10px;
    width: 100%;
    height: 100%;
  }
}

.markers {
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 8px 0 8px;
  gap: 16px;

  @media #{map-get($display-breakpoints, 'sm-and-up')} {
    display: none;
  }

  .marker-dot {
    height: 10px;
    width: 10px;
    background-color: transparent;
    border-radius: 50%;
    border: 1px solid var(--gray-light-A9);
    transition: all ease 0.3s;

    &.visible {
      background-color: var(--ui-dark-gray);
      border: none;
    }
  }
}

.section-header {
  margin-bottom: 16px;
}

.about-our-projects {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  > .projects-link {
    width: fit-content;
    display: none;
    color: var(--ui-dark-blue);
    font-size: 16px;
    line-height: 20px;

    @media #{map-get($display-breakpoints, 'sm-and-up')} {
      font-size: 24px;
      display: initial;
    }
  }
}
</style>
