import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withModifiers as _withModifiers, withCtx as _withCtx, createBlock as _createBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7dd06a79"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "invite-user-onboard" }
const _hoisted_2 = {
  key: 0,
  class: "invite-limit-reached-text"
}
const _hoisted_3 = {
  key: 2,
  class: "invitation-success"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "invitation-success-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DynamicInputGroup = _resolveComponent("DynamicInputGroup")!
  const _component_gs_button = _resolveComponent("gs-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DynamicInputGroup, {
      modelValue: _ctx.inviteEmails,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inviteEmails) = $event)),
      disabled: _ctx.loading || _ctx.invitationSuccess,
      "input-rules": [_ctx.rules.email],
      "input-number-limit": _ctx.inviteNumberLimit,
      "input-label": _ctx.t('email'),
      onValidate: _ctx.onFormValidate
    }, null, 8, ["modelValue", "disabled", "input-rules", "input-number-limit", "input-label", "onValidate"]),
    (_ctx.limitReached)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.t('limit_reached')), 1))
      : _createCommentVNode("", true),
    (!_ctx.invitationSuccess)
      ? (_openBlock(), _createBlock(_component_gs_button, {
          key: 1,
          class: "invite-user-button",
          type: "primary",
          size: "large",
          "full-width": "",
          disabled: !_ctx.isFormValid || _ctx.loading,
          loading: _ctx.loading,
          onClick: _withModifiers(_ctx.onSendInvitations, ["prevent"])
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.inviteUsersButtonText), 1)
          ]),
          _: 1
        }, 8, ["disabled", "loading", "onClick"]))
      : _createCommentVNode("", true),
    (_ctx.invitationSuccess)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("img", {
            class: "invitation-success-icon",
            src: require('@/assets/icons/check_circle_2.svg'),
            alt: "success"
          }, null, 8, _hoisted_4),
          _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.t('invitation_sent')), 1)
        ]))
      : _createCommentVNode("", true)
  ]))
}